<template>
  <div class="page page-box page-padding">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="学员姓名：">
          <el-input v-model="listQuery.Name" placeholder="请输入学员姓名" class="input-width"></el-input>
        </el-form-item>
        <el-form-item label="班级名称：">
          <template>
            <el-select v-model="listQuery.ClassName" filterable clearable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="optionLoading">
              <el-option v-for="item in options" :key="item.Id" :label="item.Name" :value="item.Name">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="listQuery.Phone" placeholder="请输入手机号" class="input-width"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button v-preventReClick @click="resetSearch" class="plain-button">重置</el-button>
          <el-button @click="addOrEidtStudent()" icon="el-icon-plus">
            <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span>添加学员</span></div> -->
            添加学员
          </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体内容 -->
    <main class="page-main-main" ref='main'>
      <!--表格渲染-->
      <el-table v-loading="loading" :data="tableList" border fit stripe>
        <el-table-column label="姓名" prop="Name" align="center" :show-overflow-tooltip="true" />
        <el-table-column label="性别" align="center" width="60px">
          <template slot-scope="{ row }">
            {{ row.Sex | studentSex}}
          </template>
        </el-table-column>
        <el-table-column label="出生年月日" prop="Birthday" align="center" min-width="100" />
        <el-table-column label="所属班级" align="center" :show-overflow-tooltip="true" min-width="160">
          <template slot-scope="{ row }">
            {{ Array.isArray(row.Classes) && row.Classes.map(item=> item.Name).join('/') }}
          </template>
        </el-table-column>
        <el-table-column label="已绑定家长及手机号" align="center" :show-overflow-tooltip="true" min-width="160">
          <template slot-scope="{ row }">
            <span v-for="(item,index) in row.Elders" :key='index'>
              {{ item.Type | elderType }}({{ item.Name }} {{ item.Phone }})
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding" width="140px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="readDetail(row)"> 详情 </el-button>
            <el-button type="text" @click="addOrEidtStudent(row)"> 编辑 </el-button>
            <el-button type="text" @click="deleteClubStudent(row)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页部分 -->
      <footer>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubStudentList" />
      </footer>
    </main>
    <!-- 新建编辑学员 -->
    <el-dialog :title="studentTitle" :close-on-click-modal='false' :visible.sync="studentVisible" width="40%">
      <el-form :model="studentForm" :rules="rules" ref='studentForm' label-width="auto">
        <el-form-item label="学员头像:" prop="HeadPortrait">
          <template>
            <div class="cropper-wrapper">
              <!-- element 上传图片按钮 -->
              <template v-if="!isPreview">
                <el-upload action="#" :show-file-list="false" :on-change="handleChangeUpload">
                  <img v-if="studentForm.HeadPortrait" :src="`${cosHttp}${studentForm.HeadPortrait}`" class="img-box">
                  <!-- <span v-else class="photo-box">上传</span> -->
                  <img v-else src="@/assets/defaultImg.png" class="img-box">
                </el-upload>
                <span class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</span>
              </template>
              <div class="pre-box" v-else>
                <el-upload action="#" :show-file-list="false" :on-change="handleChangeUpload">
                  <img v-if="previewImg" :src="previewImg" alt="裁剪图片" class="img-box" />
                </el-upload>
                <span class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</span>
              </div>
              <!-- vueCropper 剪裁图片实现-->
              <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body :close-on-click-modal='false'>
                <div class="cropper-content">
                  <div class="cropper" style="text-align: center">
                    <vueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed" :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue" :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" @cropMoving="cropMoving" />
                  </div>
                </div>
                <div class="action-box">
                  <el-upload class="upload-demo" action="#" :show-file-list="false" :on-change="handleChangeUpload">
                    <el-button type="primary" plain>更换图片</el-button>
                  </el-upload>
                  <el-button type="primary" plain @click="clearImgHandle">清除图片</el-button>
                  <el-button type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
                  <el-button type="primary" plain @click="rotateRightHandle">右旋转</el-button>
                  <el-button type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
                  <el-button type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
                  <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button class="discoloration" @click="finish" :loading="loading">确 认</el-button>
                </div>
              </el-dialog>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="学员姓名:" prop="Name">
          <el-input v-model="studentForm.Name" :readonly="repeatStatus" @blur="changeStudent()" show-word-limit maxlength="10" v-if="repeatStatus" class="readonly-input dialog-input">
            <i slot="suffix" v-if='repeatStatus' @click="changeRepeatStatus(1)" class="el-input__icon el-icon-close"></i>
          </el-input>
          <el-input class="dialog-input" v-model="studentForm.Name" :readonly="repeatStatus" @blur="changeStudent()" show-word-limit maxlength="10" v-else>
            <i slot="suffix" v-if='repeatStatus' @click="changeRepeatStatus(1)" class="el-input__icon el-icon-close"></i>
          </el-input>
        </el-form-item>
        <div class="table-box" v-if="repeatList.length > 0">
          <div class="text">重名信息学员列表</div>
          <el-table :data="repeatList" border fit stripe>
            <el-table-column prop="Name" label="学员姓名（备注）" align="center" />
            <el-table-column label="已绑定家长及手机号" align="center" :show-overflow-tooltip="true">
              <template slot-scope="{ row }">
                <span v-for="(item,index) in row.Elders" :key='index'>
                  {{ item.Type !== 8 ? row.Name : '' }}{{ item.Type | elderType }}({{ item.Phone }})
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="MemberCount" label="所属班级" align="center" :show-overflow-tooltip="true">
              <template slot-scope="{ row }">
                {{ Array.isArray(row.Classes) && row.Classes.map(item=> item.Name).join('/') }}
              </template>
            </el-table-column>
          </el-table>
          <div class="text text-color" @click="changeRepeatStatus()">以上都不是，新建重名备注</div>
        </div>
        <el-form-item label="重名备注:" prop="RepeatName" v-if="repeatStatus">
          <el-row>
            <el-col :span="8">
              <el-input class="dialog-input" v-model="Name" readonly disabled></el-input>
            </el-col>
            <el-col :span="8" :offset="2">
              <el-input v-model="repeatName" placeholder="请输入重名备注"></el-input>
            </el-col>
            <el-col :span="4" :offset="2">
              <el-button @click="addRepeatName()" class="discoloration"> 添加 </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <div v-if="repeatParent !== 0" class="repeatParent-box">
          <span v-if="repeatParent === 1" class="success">当前学员姓名+重名备注可用，俱乐部内无重复信息</span>
          <span v-else class="error">当前学员姓名+重名备注不可用，请重新输入重名备注</span>
        </div>
        <el-form-item label="性别:" required>
          <el-radio-group v-model="studentForm.Sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年龄:" prop='Birthday' class="alignItem">
          <el-row>
            <el-col :span="20">
              <el-date-picker class='select-box' :picker-options="pickerOptions" v-model="studentForm.Birthday" placeholder="选择日期">
              </el-date-picker>
            </el-col>
            <el-col :span="3" :offset="1">
              <span>{{Age}}岁</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="身份证号:" prop="IdCard">
          <el-input class="dialog-input" v-model="studentForm.IdCard" placeholder="请输入学员身份证号"></el-input>
        </el-form-item>
        <el-form-item label="所属班级:" prop='Class'>
          <el-select class="class-input" @change='changeClassStatus' filterable multiple v-model="studentForm.ClassIds" placeholder="请选择">
            <el-option v-for="item in classList" :key="item.Id" :label="item.Name" :value="item.Id">
              <div class="select-option-box">
                <span class="option-left">{{ item.Name }}</span>
                <span class="option-right">{{ item.SubOrgName }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联家长:" required>
          <el-row v-for="(item,index) in selectParentList" :key='index'>
            <el-col :span="5" class="col-box">
              <el-select class="select-box" @change='changeType(index,item)' v-model="item.Type" placeholder="请选择">
                <el-option v-for="item in parentTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7" :offset="1">
              <el-input class="dialog-input" v-model="item.Name" :placeholder="item.placeholder" maxlength="10"></el-input>
            </el-col>
            <el-col :span="7" :offset="1">
              <el-input class="dialog-input" v-model="item.Phone" placeholder="请输入手机号"></el-input>
            </el-col>
            <el-col :span="2" :offset="1"><i class="el-icon-delete" @click="deleteParent(index)"></i></el-col>
          </el-row>
          <el-button icon="el-icon-plus" @click="addParent" class="discoloration">添加联系人</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="studentVisible = false" class="plain-button">取 消</el-button>
        <el-button v-preventReClick @click="updateClubStudent()" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog title="详情" :visible.sync="readVisible" width="40%" :close-on-click-modal='false'>
      <el-row class="margin-row">
        <el-col :span="4">
          <span>学员头像:</span>
        </el-col>
        <el-col :span="4">
          <span>
            <el-image v-if="readStudentForm.HeadPortrait" :src="`${cosHttp}${readStudentForm.HeadPortrait}`" :preview-src-list="[`${cosHttp}${readStudentForm.HeadPortrait}`]">
            </el-image>
            <el-image v-else :src="`${defaultImg}`" :preview-src-list="[`${defaultImg}`]">
            </el-image>
          </span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>学员姓名：</span><span>{{ readStudentForm.Name }}</span>
        </el-col>
        <el-col :span="12">
          <span>学员性别：</span><span>{{ readStudentForm.Sex|studentSex }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>出生年月：</span><span>{{ readStudentForm.Birthday }}</span>
        </el-col>
        <el-col :span="12">
          <span>身份证号：</span><span>{{ readStudentForm.IdCard }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col>
          <span>所属班级：</span><span>{{ Array.isArray(readStudentForm.Classes) && readStudentForm.Classes.map(item=> item.Name).join('/') }}
          </span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col>
          <h4>已绑定家长及手机号</h4>
        </el-col>
        <el-col>
          <span v-for="(item,index) in readStudentForm.Elders" :key='index'>
            <div class="line-feed">
              {{index+1}}. {{ item.Type | elderType }}({{ item.Type !== 7 ? item.Name : '' }} {{ item.Phone }})
            </div>
          </span>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { getClubStudentList, delClubStudent, updateClubStudent, getAllClubClass, classNameFind } from "@/service/educational";
import pagination from '@/components/pagination/pagination.vue';
import { deepClone } from '@/utils/index';
import { parentTypeList } from '@/config/data';
import moment from 'moment';
import upload from '@/mixins/upload';
import { VueCropper } from 'vue-cropper';

const StudentForm = { // 新增编辑学员的初始化数据
  Name: '',
  Sex: 1,
  Birthday: '',
  ClassIds: [],
  IdCard: '',
  Elders: [],
  HeadPortrait: ''
};

const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
  Name: '',
  ClassName: [],
  Phone: ''
};

export default {
  name: "Student",
  mixins: [upload],
  components: {
    pagination,
    VueCropper
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (this.repeatParent !== 1 && this.repeatList.length > 0) {
        return callback(new Error('存在重名学员，请确认学员是否已添加'));
      }
      if (!value || value.trim() === '') {
        return callback(new Error('请输入学员姓名'));
      }
      callback();
    };

    const validateBirthday = (rule, value, callback) => {
      if (!this.studentForm.Birthday) {
        return callback(new Error('请选择出生年月'));
      }
      callback();
    };

    const validateRepeatName = (rule, value, callback) => {
      if (!this.repeatName || this.repeatName.trim() === '') {
        return callback(new Error('请输入重名学员家长名称'));
      }
      callback();
    };

    const validateClass = (rule, value, callback) => {
      if (Array.isArray(this.studentForm.ClassIds) && this.studentForm.ClassIds.length > 0) {
        callback();
      }
      return callback(new Error('请选择所属班级'));
    };

    const validateIdCard = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        callback();
      } else {
        let p = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        if (p.test(value.trim())) {
          callback();
        }
        return callback(new Error('请输入正确的身份证'));
      }
    };

    return {
      defaultImg: require('@/assets/defaultImg.png'),
      options: [],
      parentTypeList,
      studentForm: deepClone(StudentForm),
      listQuery: deepClone(ListQuery),
      tableList: [], // 表格数据
      loading: false, // 加载状态
      optionLoading: false,
      total: 0,  // 表格总条数
      studentTitle: '新增学员', // 新建编辑title
      studentVisible: false, //  新建编辑学员弹窗状态
      rules: {
        Name: [{ required: true, validator: validateName, trigger: 'blur' }],
        Birthday: [{ required: true, validator: validateBirthday, trigger: 'blur' }],
        IdCard: [{ validator: validateIdCard, trigger: 'blur' }],
        Class: [{ required: true, validator: validateClass, trigger: 'blur' }],
        RepeatName: [{ required: true, validator: validateRepeatName, trigger: 'blur' }],
      },
      classList: [], // 所属班级列表
      selectParentList: [], // 添加家长列表
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > (Date.now() - 3600 * 1000 * 24); // 
        },
      },
      readVisible: false,//详情弹窗初始状态
      readStudentForm: {},//详情数据
      repeatList: [], // 重名列表
      repeatName: '',  // 重名家长名称
      repeatStatus: false, // 是否点击重名
      repeatParent: 0, // 重名备注是否重复
      Name: '',   // 名字暂存
      parents: [],// 家长联系暂存
      isPreview: false,
      dialogVisible: false,
      previewImg: '', // 预览图片地址
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false,
      cosHttp: process.env.VUE_APP_COSHTTP,
    };
  },

  computed: {
    Age() {
      if (!this.studentForm.Birthday) {
        return 0
      }
      // 年
      let age = new Date().getFullYear() - moment(this.studentForm.Birthday).format('YYYY')
      // 判断月
      if (new Date().getMonth() + 1 - moment(this.studentForm.Birthday).format('MM') > 0) {
        return age
      }
      // 判断日
      if (new Date().getMonth() + 1 - moment(this.studentForm.Birthday).format('MM') == 0) {
        if (new Date().getDate() - moment(this.studentForm.Birthday).format('DD') > 0) {
          return age
        }
      }
      return age - 1

    }
  },

  created() {
    this.getClubStudentList();
    this.getAllClubClass();
    // this.classNameFind();

  },
  methods: {

    remoteMethod(query) {
      if (query && query.trim() !== '') {
        this.optionLoading = true;
        setTimeout(() => {
          this.optionLoading = false;
          this.options = this.classList.filter(item => {
            return item.Name.toLowerCase()
              .indexOf(query.trim().toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = this.classList;
      }
    },

    /**
     * 获取所有班级列表
     */
    // async classNameFind() {
    //   let res = await classNameFind();
    //   this.classList = res.map((item, index) => {
    //     let obj = {};
    //     obj.Name = item;
    //     obj.Id = index + 1
    //     return obj;
    //   });
    //   this.options = this.classList;
    // },
    async getAllClubClass() {
      this.classList = await getAllClubClass();
      this.options = this.classList;
    },

    /**
     * 获取学员列表数据
     */
    async getClubStudentList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubStudentList(this.listQuery);
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },
    /**
     * 查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.listQuery.ClassName = this.listQuery.ClassName || undefined;
      this.options = this.classList;
      this.getClubStudentList();
    },

    /**
     * 重置
     */

    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.options = this.classList;
      this.getClubStudentList();
    },

    /**
     * 查看
     */
    readDetail(row) {
      this.readVisible = true;
      this.readStudentForm = { ...row };
      console.log(this.readStudentForm);
    },

    /**
     * 新增或者编辑
     */
    addOrEidtStudent(row) {
      this.repeatList = [];
      this.repeatName = '';
      this.repeatParent = 0;
      this.repeatStatus = false;
      this.selectParentList = [];
      this.studentVisible = true;
      this.isPreview = false;
      this.$nextTick(() => {
        this.$refs.studentForm.clearValidate();
      })

      if (row) {
        this.studentForm = { ...row };
        this.studentForm.ClassIds = row.Classes.map(item => item.Id);
        this.selectParentList = Array.isArray(row.Elders) && row.Elders.map(item => {
          return {
            Type: item.Type,
            Phone: item.Phone,
            Name: item.Name,
            Id: item.Id,
            placeholder: item.Type === 8 ? '请输入其他' : '请输入家长姓名'
          }
        }) || [];
        // this.changeStudent('edit');
        let startIndex = this.studentForm.Name.indexOf('(');
        let stopIndex = this.studentForm.Name.indexOf(')');
        if (startIndex !== -1) {
          this.repeatStatus = true;
          this.Name = this.studentForm.Name.substring(0, startIndex);
          this.repeatName = this.studentForm.Name.substring(startIndex + 1, stopIndex);
        }
        this.studentTitle = '编辑学员';
      } else {
        this.studentForm = deepClone(StudentForm);
        this.studentTitle = '新增学员';
      }
    },

    /**
     * 确定新增或者编辑
     */
    async updateClubStudent() {

      if (Array.isArray(this.selectParentList) && this.selectParentList.length === 0) {
        this.$message.error('请添加家长联系方式');
        return;
      };

      let flag = true;
      // 判断家长联系方式是否填写完毕
      Array.isArray(this.selectParentList) && this.selectParentList.forEach(item => {
        if (!item.Phone || item.Phone.trim() === '' || !item.Name || item.Name.trim() === '') {
          flag = false;
        }
      });

      if (this.studentTitle === '新增学员') {
        await this.changeStudent();
      }

      if (!flag) {
        this.$message.error('请填写家长姓名或联系方式');
        return;
      }

      let arr = this.selectParentList.map(item => {
        return {
          Type: item.Type,
          Phone: item.Phone,
          Name: item.Name,
          Id: item.Id || undefined
        };
      })

      this.$refs.studentForm.validate(async (valid) => {
        if (!valid) return;

        if (this.studentTitle === '编辑学员') {
          await updateClubStudent({
            Id: this.studentForm.Id,
            Sex: this.studentForm.Sex,
            ClassIds: this.studentForm.ClassIds,
            IdCard: this.studentForm.IdCard,
            Name: this.studentForm.Name,
            Elders: [...arr],
            Birthday: moment(this.studentForm.Birthday).format('YYYY-MM-DD'),
            HeadPortrait: this.studentForm.HeadPortrait
          });
          this.$message.success('编辑成功');
        } else {
          await updateClubStudent({
            ...this.studentForm,
            Elders: [...arr],
            Birthday: moment(this.studentForm.Birthday).format('YYYY-MM-DD')
          });
          this.$message.success('新建成功');
        }
        this.studentVisible = false;
        this.getClubStudentList();
      })
    },

    /**
     * 删除
     */
    async deleteClubStudent(row) {
      try {
        let res = await this.$confirm('是否确认删除学员？删除后学员将退出所属班级，绑定家长信息失效', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删 除',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        if (res !== 'confirm') return;
        await delClubStudent({ Id: row.Id });
        this.$message.success('删除成功');
        this.getClubStudentList();
      } catch (err) { };
    },

    /**
     * 添加家长
     */
    addParent() {
      if (this.selectParentList.length === 8) {
        this.$message.error('最多添加8个身份');
        return;
      }
      let obj = {
        Type: 1,
        Phone: '',
        Name: '',
        placeholder: '请输入家长姓名'
      };
      let arr = deepClone(this.selectParentList).sort((a, b) => a.Type - b.Type)
      arr.forEach(item => {
        if (item.Type === obj.Type) {
          obj.Type += 1;
        }
      })
      this.selectParentList.push(obj);
      this.parents = deepClone(this.selectParentList);
    },

    /**
     * 删除家长
     */
    deleteParent(index) {
      this.selectParentList.splice(index, 1);
    },

    /**
     * 选择所属班级时改变验证状态
     */
    changeClassStatus() {
      this.$refs.studentForm.validateField('Class');
      this.$forceUpdate();
    },

    /**
     * 选择所属年龄段时改变验证状态
     */
    changeAgeStatus() {
      this.$refs.studentForm.validateField('Age');
    },

    /**
     * 输入学员失焦
     */
    async changeStudent(edit) {
      if (!this.studentForm.Name || this.studentForm.Name.trim() === '' || this.repeatStatus) {
        return;
      }
      let res = await getClubStudentList({ Name: this.studentForm.Name.trim() });
      this.repeatList = [];
      if (res.DataList.length > 0) {
        this.repeatList = res.DataList.filter(item => {
          if (edit) {
            return item.Name.indexOf(`${this.studentForm.Name.trim()}(`) !== -1;
          } else {
            return this.studentForm.Id !== item.Id && item.Name === this.studentForm.Name.trim() || item.Name.indexOf(`${this.studentForm.Name.trim()}(`) !== -1;
          }
        })
      }
      this.$refs.studentForm.validateField('Name');
    },

    /**
     * 点击以上都不是，添加备注
     */
    changeRepeatStatus(flag) {
      if (!this.repeatStatus) {
        this.Name = this.studentForm.Name;
      }
      this.repeatStatus = true;
      if (flag) {
        this.repeatList = [];
        this.repeatName = '';
        this.studentForm.Name = '';
        this.repeatParent = 0;
        this.repeatStatus = false;
        this.$refs.studentForm.validateField('Name');
      }
    },

    /**
     * 添加备注
     */
    async addRepeatName() {
      if (!this.repeatName || this.repeatName.trim() === '') {
        this.$refs.studentForm.validateField('RepeatName');
        return;
      }
      this.studentForm.Name = this.Name + `(${this.repeatName})`;
      let res = await getClubStudentList({ Name: this.studentForm.Name.trim() });
      let arr = res.DataList.filter(item => {
        return item.Id !== this.studentForm.Id && item.Name === this.studentForm.Name.trim();
      });
      this.repeatParent = 1;
      if (arr.length > 0) {
        this.repeatParent = 2;
      }
      this.$refs.studentForm.validateField('Name');
      this.$refs.studentForm.validateField('RepeatName');
    },

    /**
     * 改变家长身份
     */
    changeType(index) {
      this.selectParentList.forEach((item, subIndex) => {
        if (subIndex !== index && item.Type === this.selectParentList[index].Type) {
          this.selectParentList[index].Type = this.parents[index].Type;
          this.$message.error('已经有相同身份');
        }
      })
      this.selectParentList[index].placeholder = '请输入家长姓名';
      if (this.selectParentList[index].Type === 8) {
        this.selectParentList[index].Name = this.studentForm.Name;
        this.selectParentList[index].placeholder = '请输入其他';
      }
      this.parents = deepClone(this.selectParentList);
    },

    // 正在上传中...
    uploading(progressData) {
      let index = this.fileList.length - 1;
      this.fileList[index].percentage = progressData.percent * 100;
      this.fileList[index].status = 'uploading';
    },

    // 上传按钮 限制图片大小和类型
    handleChangeUpload(file) {
      if (JSON.parse(JSON.stringify(file)).status === 'ready') {
        const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/jpg';
        const isLt20M = file.raw.size / 1024 / 1024 < 20;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
          return false;
        }
        if (!isLt20M) {
          this.$message.error('上传头像图片大小不能超过 20MB!');
          return false;
        }
        this.fileName = file.name.split('.')[0];
        // 上传成功后将图片地址赋值给裁剪框显示图片
        this.$nextTick(async () => {
          this.loading = true;
          // base64方式
          // this.option.img = await fileByBase64(file.raw)
          this.option.img = URL.createObjectURL(file.raw)
          this.loading = false;
          this.dialogVisible = true;
        })
      }
    },
    // 放大/缩小
    changeScaleHandle(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    // 下载
    downloadHandle(type) {
      let aLink = document.createElement('a');
      aLink.download = 'author-img';
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          let downImg = window.URL.createObjectURL(data);
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          let downImg = c;
          aLink.href = data;
          aLink.click();
        })
      }
    },
    // 清理图片
    clearImgHandle() {
      this.option.img = '';
    },
    // 截图框移动回调函数
    cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
    },
    async finish() {
      // 获取截图的 blob 数据
      this.$refs.cropper.getCropData((data) => {
        this.loading = true;
        this.dialogVisible = false;
        this.previewImg = data;
        this.isPreview = true;
        this.loading = false;

        let arr = data.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let suffix = mime.split('/')[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let file = new File([u8arr], `${this.fileName}.${suffix}`, {
          type: mime
        });
        this.uploadTecent(file).then(url => {
          this.studentForm.HeadPortrait = url;
        });
      })
    },
  },
};
</script>
<style lang='scss' scoped>
.select-box {
  width: 100%;
}
.line-feed {
  // text-indent: 9em;
  margin: 10px 0;
}
.table-box {
  padding: 16px 0;
  margin: 0 24px;
  .text {
    margin: 16px auto;
    width: 168px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 5px 0;
  }
  .text-color {
    color: #8fcc80;
    cursor: pointer;
  }
  /deep/ .el-table {
    min-height: 0 !important;
  }
}
.repeatParent-box {
  display: flex;
  justify-content: center;
  .success {
    padding: 5px;
    border: 1px solid rgb(183, 235, 143);
    border-radius: 4px;
    background: rgb(183, 235, 143);
  }
  .error {
    padding: 5px;
    border: 1px solid rgb(255, 163, 158);
    border-radius: 4px;
    background: rgb(255, 163, 158);
  }
}
.el-icon-close {
  cursor: pointer;
}
.readonly-input {
  /deep/.el-input__inner {
    background-color: #fafafa;
  }
}
.class-input {
  width: 100%;
}

.cropper-content {
  .cropper {
    width: auto;
    height: 350px;
  }
}
.action-box {
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 0;
  }
  margin-top: 24px;
}
/deep/.el-dialog__footer {
  padding-right: 24px !important;
}
.img-box {
  border: 1px dashed #8fcc80;
  border-radius: 50%;
  width: 64px;
  height: 64px;
}
.photo-box {
  display: inline-block;
  width: 64px;
  height: 64px;
  line-height: 64px;
  border: 1px dashed #8fcc80;
  border-radius: 50%;
}
/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
</style>
